<template>
  <!-- :start-time="'2018-10-10 00:00:00'"
    :end-time="1481450115" -->
  <div class="countdown-timer">
    <vue-countdown-timer
      @start_callback="startCallBack('event started')"
      @end_callback="endCallBack('event ended')"
      :start-time="Date.now()"
      :end-time="end_at ? end_at : endAt"
      :end-text="'00:00:00:00'"
      :day-txt="sbliterText"
      :hour-txt="sbliterText"
      :minutes-txt="sbliterText"
      :seconds-txt="null"
    >
      <template slot="countdown" slot-scope="scope">
        <span>{{ scope.props.days }}</span
        ><span>{{ scope.props.dayTxt }}</span>
        <span>{{ scope.props.hours }}</span
        ><span>{{ scope.props.hourTxt }}</span>
        <span>{{ scope.props.minutes }}</span
        ><span>{{ scope.props.minutesTxt }}</span>
        <span>{{ scope.props.seconds }}</span
        ><span>{{ scope.props.secondsTxt }}</span>
      </template>
      <template slot="end-text" slot-scope="scope">
        <span style="color: red">{{ scope.props.endText }}</span>
      </template>
    </vue-countdown-timer>
  </div>
</template>

<script>
export default {
  name: "Timer",
  data() {
    return {
      sbliterText: ":",
      endAt: new Date().getTime() + 5000,
    };
  },
  methods: {
    startCallBack: function (x) {
      console.log(x);
    },
    endCallBack: function (x) {
      console.log(x);
    },
  },
};
</script>
<style scoped lang="scss">
.countdown-timer {
  direction: ltr;
  text-align: center;
}
</style>
